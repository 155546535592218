.App {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.content {
  flex: 1 0 auto;
}

.footer {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}

.footer-main-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-top: 2px solid #f1701b;
}

.App-header {
  background-color: #f1701b;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
  height: 50px;
}

.new-line {
  white-space: pre-line;
}

.statusTitle {
  font-size: large;
  font-weight: bold;
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}